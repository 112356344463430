.logoaskew {
    text-indent: 0;
    -webkit-animation-name: unskew;
    animation-name: unskew;
    -webkit-animation-duration: .6s;
    animation-duration: .6s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-timing-function: cubic-bezier(.75, -2, .75, 2);
    animation-timing-function: cubic-bezier(.75, -2, .75, 2);
    -webkit-transform-origin: 86% 15%;
    transform-origin: 86% 15%;
}

/* https://codepen.io/rauldronca/pen/ZyNQPX */

@keyframes shake {
    0% { transform: skewX(-15deg); }
    5% { transform: skewX(15deg); }
    10% { transform: skewX(-15deg); }
    15% { transform: skewX(15deg); }
    20% { transform: skewX(0deg); }
    100% { transform: skewX(0deg); }  
}

.shakyshaky {
    display: inline-block;
    animation: shake 1s infinite alternate;
  }
  
