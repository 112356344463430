.playingcard {
    width: 20vw;
    height: fit-content;
}

.cardtypographytitle {
    color: purple !important;
    font-family: 'Press Start 2P', cursive !important;
    font-size: 2vmin !important;
}

.cardtypographybody {
    color: black !important;
    font-family: 'Press Start 2P', cursive !important;
    font-size: 1.5vmin !important;
}