
.removeallmarginpadding{
    margin:0 ! important;
    padding:0 ! important;
}

.widthsmall {
    max-width: 10vw;
}


.appfontwhite {
    color: #FFFFFF !important;
    font-family: 'Press Start 2P', cursive !important;
}

.appfontblack {
    color: #000000 !important;
    font-family: 'Press Start 2P', cursive !important;
}

/* TODO make sure these are for buttons only */
a:link { text-decoration: none; }

a:visited { text-decoration: none; }

a:hover { text-decoration: none; }

a:active { text-decoration: none; }