.activitycarousel {
    padding-top: 20vh;
}

.taskactivityappfontwhite {
    color: #FFFFFF !important;
    font-family: 'Press Start 2P', cursive !important;
    font-size: 18px;
}

.taskactivityappfontblack {
    color: #000000 !important;
    font-family: 'Press Start 2P', cursive !important;
    font-size: 18px;
}