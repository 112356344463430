.cartoonbutton{
    margin-left: 5px;
    margin-right: 10px;
    position:relative;
    display:inline-block;
  }
  
  .cartoonbutton a{

    text-align: center;
    text-decoration:none;
    background-color:#FFA12B;
    display:block;
    position:relative;
    padding:20px 40px;
    
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    text-shadow: 0px 1px 0px #000;
    filter: dropshadow(color=#000, offx=0px, offy=1px);
    
    -webkit-box-shadow:inset 0 1px 0 #FFE5C4, 0 10px 0 #915100;
    -moz-box-shadow:inset 0 1px 0 #FFE5C4, 0 10px 0 #915100;
    box-shadow:inset 0 1px 0 #FFE5C4, 0 10px 0 #915100;
    
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }
  
  .cartoonbutton a:active{
    top:10px;
    background-color:#F78900;
    
    -webkit-box-shadow:inset 0 1px 0 #FFE5C4, inset 0 -3px 0 #915100;
    -moz-box-shadow:inset 0 1px 0 #FFE5C4, inset 0 -3pxpx 0 #915100;
    box-shadow:inset 0 1px 0 #FFE5C4, inset 0 -3px 0 #915100;
  }
  
  .cartoonbutton:after{
    content:"";
    height:100%;
    width:100%;
    padding:4px;
    position: absolute;
    bottom:-15px;
    left:-4px;
    z-index:-1;
    background-color:#2B1800;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }


  /*
  Stripped button
  */

@keyframes stripe-slide {
    0% { background-position: 0% 0; }
    100% { background-position: 100% 0; }
}

.stripebutton {
    margin-left: 5px;
    margin-right: 10px;
    background : transparent;
    line-height : normal;
    cursor : pointer;
    display: block;
    padding: 16px 36px 22px;
    background-color: orange;
    border: 2px solid #666;
    border-radius: 6px;
    margin-bottom: 16px;
    transition: all .5s ease;
    overflow: hidden;
    position: relative;
}

.stripebutton::after {
  content: '';
  display: block;
  height: 7px;
  width: 100%;
  background-image: repeating-linear-gradient(
      45deg,
      #666,
      #666 1px,
      transparent 2px,
      transparent 5px
  );
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-top: 1px solid #666;
  position: absolute;
  left: 0;
  bottom: 0;
  background-size: 7px 7px;
}


.stripebutton:hover {
  background-color: #fff;
  border-color: #666;

}

.stripebutton:hover::after {
  background-image: repeating-linear-gradient(
      45deg,
      #666,
      #666 1px,
      transparent 2px,
      transparent 5px
  );
  border-top: 1px solid #666;
  animation: stripe-slide 12s infinite linear forwards;
}


.stripebuttonactionfont {
  font-family: 'Press Start 2P', cursive !important;
  color: black !important;
}