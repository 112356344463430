.navbarfontclass {
    color: black !important;
}

.hometaglinetypography {
    color: #FFFFFF !important;
    font-family: 'Press Start 2P', cursive !important;
    font-size: 5vmin !important;
    transform: rotate(0deg);
}

.homebuttontypography {
    color: black;
    font-family: 'Press Start 2P', cursive !important;
    font-weight:bold;
    font-size:18px;
}

.homebuttontypography > a {
    color:black;
    font-family: 'Press Start 2P', cursive !important;
    font-weight:bold;
    font-size:18px;
}